import './App.css';
import {React, useState } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";

function App() {
  const { unityProvider, loadingProgression, sendMessage } = useUnityContext({
    loaderUrl: "build/FuddiesGame.loader.js",
    dataUrl: "build/FuddiesGame.data",
    frameworkUrl: "build/FuddiesGame.framework.js",
    codeUrl: "build/FuddiesGame.wasm",
  });

  const [volumeActive, toggleVolume] = useState(false);

  function handleClick(data) {
    toggleVolume(!volumeActive);
    sendMessage("PersistentObject", "SetVolume", volumeActive ? 1 : 0);
    data.target.style.src = volumeActive ? "./images/SoundOff.png" : "./images/SoundOn.png";
  }

  return (
    <div id="container">
      <div id="middleArea">
        <div class="sideAreas">
          <div class="bird" id="redBird"/>
        </div>
        <div id="gameArea">
          <Unity id="gameContainer" unityProvider={unityProvider}/>
          {loadingProgression < 1 && (
          <progress/>
          )}
        </div>
        <div class="sideAreas">
          <div class="bird" id="purpleBird"/>
          <div class="bird" id="yellowBird"/>
        </div>
      </div>
      <div id="bottomArea">
        <div>
          <p id="madeBy">
            A GAME BY <div id="cat"/> <a href="https://bento.me/vibingstudios" target="_blank" rel="noopener noreferrer">VIBING STUDIOS</a>
          </p>
          <div id="volumeHolder">
            <img onClick={handleClick} id="volumeButton" src={require(volumeActive ? "./images/SoundOff.png" : "./images/SoundOn.png")}/>
          </div>
        </div>
        <div id="itemStack">
          <div id="Land" class="imageStackItem"/>
          <div id="Grass" class="imageStackItem"/>
        </div>
      </div>
    </div>
  );
}

export default App;
